import Vue from "vue";
import Vuex from "vuex";
import account from "./modules/account";
import kpi from "./modules/kpi";
import alerts from "./modules/alerts";
import analysis from "./modules/analysis";
import rules from "./modules/rules";
import contact from "./modules/contact";
import layout from "./modules/layout";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    account,
    kpi,
    alerts,
    analysis,
    rules,
    contact,
    layout,
  },
});
