import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "@/store/index";
import { Auth, Hub } from "aws-amplify";
import { HTTP } from "@/store/modules/http-common";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function fetchCognito() {
  return Auth.currentAuthenticatedUser()
    .then(async (data) => {
      if (data && data.signInUserSession) {
        store.commit("account/SET_COGNITO", data);

        const currentSession = await Auth.currentSession();
        const { jwtToken } = currentSession.accessToken;

        HTTP.defaults.headers.common['Authorization'] = jwtToken;

        HTTP.interceptors.response.use(function (response) {
          return response;
        }, function (error) {
          if (500 === error.response.status) {
            store.commit(
              "layout/SET_SNACK_DATAS",
              {
                text:
                  "Une erreur est survenue, merci de réessayer plus tard",
                color: "error",
              },
              { root: true }
            );
          } else if (401 === error.response.status) {
            Auth.signOut();
          } else {
            return Promise.reject(error);
          }
        });

        return data;
      }
      return null;
    })
    .catch(() => {
      store.commit("account/SET_COGNITO", null);
      return null;
    });
}

router.beforeEach(async (to, from, next) => {
  if (to !== from && to.matched.some((record) => record.name !== "Login")) {
    var cognito = await fetchCognito();
    if (!cognito) {
      return next({
        path: "/login",
        query: {
          redirect: to.fullPath,
        },
      });
    }

    if (!store.state.account.user) {
      await store.dispatch("account/fetchUser");
    }

    return next();
  }
  return next();
});


const listener = (data) => {
  switch (data.payload.event) {
    case 'signIn':
      router.push('/');
      console.log('user signed in');
      break;
    case 'signUp':
      console.log('user signed up');
      break;
    case 'signOut':
      console.log('user signed out');
      store.dispatch("account/purgeSession");
      router.push('/login');
      break;
    case 'signIn_failure':
      console.log('user sign in failed');
      break;
    case 'tokenRefresh':
      console.log('token refresh succeeded');
      break;
    case 'tokenRefresh_failure':
      console.log('token refresh failed');
      break;
    case 'configured':
      console.log('the Auth module is configured');
  }
}
Hub.listen('auth', listener);

export default router;
