import { Translations } from '@aws-amplify/ui-components';
import { I18n } from 'aws-amplify';
import dictionary from './locales/AmplifyI18nAWS';

// https://github.com/aws-amplify/amplify-js/blob/main/packages/aws-amplify-react-native/src/AmplifyI18n.ts
I18n.putVocabularies(dictionary);

const dict = {
  en: {
    'Custom auth lambda trigger is not configured for the user pool.': 'Please enter your password',
  },
  fr: {
    // https://github.com/aws-amplify/amplify-js/blob/main/packages/amplify-ui-components/src/common/Translations.ts
    // [Translations.SIGN_IN_HEADER_TEXT]: 'Compte Edel',
    // [Translations.SIGN_IN_ACTION]: 'Connexion',
    [Translations.USERNAME_LABEL]: 'E-mail *',
    // [Translations.USERNAME_PLACEHOLDER]: 'adresse e-mail',
    [Translations.PASSWORD_LABEL]: 'Mot de passe *',
    // [Translations.PASSWORD_PLACEHOLDER]: 'Mot de passe',
    // [Translations.RESET_PASSWORD_TEXT]: 'Mot de passe oublié ?',
    [Translations.FORGOT_PASSWORD_TEXT]: ' ',
    // reset password
    // [Translations.RESET_YOUR_PASSWORD]: 'Mot de passe oublié ?',
    // [Translations.SEND_CODE]: 'Recevoir un code par e-mail',
    // [Translations.BACK_TO_SIGN_IN]: 'Retour',
    // https://github.com/aws-amplify/amplify-js/blob/main/packages/auth/src/common/AuthErrorStrings.ts
    // [Translations.EMPTY_USERNAME]: "L'email ne peut pas être vide",

    'User does not exist.': "Le nom d'utilisateur ou le mot de passe est incorrect",
    'Incorrect username or password.': "Le nom d'utilisateur ou le mot de passe est incorrect",
    'Custom auth lambda trigger is not configured for the user pool.': 'Le mot de passe doit être renseigné',
    'Username/client id combination not found.': 'Utilisateur inconnu.',
    'Cannot reset password for the user as there is no registered/verified email or phone_number': "Il n'est pas possible de réinitialiser votre mot de passe car votre email n'est pas vérifié",
  },
};

I18n.putVocabularies(dict);
