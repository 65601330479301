import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import i18n from "./i18n";
import "./i18nAmplify";
import "./filters";
import Amplify, { Auth } from "aws-amplify";
import awsconfig from "./aws-exports";
import "@aws-amplify/ui-vue";
import VueApexCharts from "vue-apexcharts";

Vue.config.productionTip = false;

Amplify.configure(awsconfig);
Auth.configure();

Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

new Vue({
  vuetify,
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
