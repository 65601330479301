import Vue from "vue";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  formatCurrency,
  formatDate,
  formatDateLong,
  humanDate,
} from "../services/alert.js";

dayjs.extend(relativeTime);

Vue.filter("capitalize", (value) => {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("formatDateHour", (date) => {
  if (!date) return "";
  return dayjs(date).add(2, 'h').format("DD/MM/YYYY à HH:mm");
});

Vue.filter("formatDate", formatDate);
Vue.filter("formatDateLong", formatDateLong);
Vue.filter("humanDate", humanDate);
Vue.filter("formatCurrency", formatCurrency);
