<template>
  <v-container>
    <v-row>
      <v-col v-for="card in unseenCards" :key="card.name">
        <UnseenAlertsCard :cardConfig="card" :counter="getCounter(card.name)" />
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="target.profile_type == 'MAGASINS'" cols="12" md="6">
        <TopCard
          :topConfig="topCashiersAlerts"
          :topData="topCashiersAlertsData"
        />
      </v-col>
      <v-col v-if="target.profile_type == 'MAGASINS'" cols="12" md="6">
        <TopCard :topConfig="topCashiersRisk" :topData="topCashiersRiskData" />
      </v-col>
      <v-col cols="12" md="6">
        <TopCard
          :topConfig="topLoyaltyCardsAlerts"
          :topData="topLoyaltyCardsAlertsData"
        />
      </v-col>
      <v-col cols="12" md="6">
        <TopCard
          :topConfig="topLoyaltyCardsRisk"
          :topData="topLoyaltyCardsRiskData"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {
    UnseenAlertsCard: () => import("@/components/Dashboard/CardUnseenAlerts"),
    TopCard: () => import("@/components/Dashboard/CardTop"),
  },
  data: () => ({
    topCashiersAlerts: {
      title:
        "Classement des utilisateurs caisse selon le nombre d’alertes",
      columns: ["Identifiant utilisateur caisse", "Nombre d'alertes"],
      noDataText: "Aucune alerte ces 7 derniers jours",
      type: "cashier",
    },
    topCashiersRisk: {
      title:
        "Classement des utilisateurs caisse selon le montant du risque financier",
      columns: ["Identifiant utilisateur caisse", "Risque financier"],
      noDataText: "Aucune alerte ces 7 derniers jours",
      type: "cashier",
    },
    topLoyaltyCardsAlerts: {
      title: "Classement des cartes de fidélité selon le nombre d’alertes",
      columns: ["Numéro carte de fidélité", "Nombre d'alertes"],
      noDataText: "Aucune alerte ces 7 derniers jours",
      type: "card",
    },
    topLoyaltyCardsRisk: {
      title:
        "Classement des cartes de fidélité selon le montant du risque financier",
      columns: ["Numéro carte de fidélité", "Risque financier"],
      noDataText: "Aucune alerte ces 7 derniers jours",
      type: "card",
    },
  }),
  computed: {
    unseenCards() {
      const unseenConfigs = {
        DEFAULT: [],
        MAGASINS: [
          {
            name: "loyaltycard",
            color: "teal",
            icon: "card-account-details-star",
            rules: ["RLMAGCPT001", "RLMAGCPT002", "RLMAGCPT003", "RLMAGCPT010", "RLMAGCPT011"],
          },
          {
            name: "cashregister",
            color: "deep-purple",
            icon: "cash-register",
            rules: ["RLMAGCAI001", "RLMAGCAI002", "RLMAGCAI003", "RLMAGCAI004", "RLMAGCAI007", "RLMAGCAI008"],
          },
          {
            name: "setup",
            color: "cyan",
            icon: "tools",
            rules: ["RLMAGGLB001", "RLMAGGLB003"],
          },
        ],
        GALEC: [
          {
            name: "loyaltycard",
            color: "teal",
            icon: "card-account-details-star",
            rules: ["RLGALCPT001", "RLGALCPT002", "RLGALCPT010", "RLGALCPT011", "RLGALCPT012", "RLGALCPT013"],
          },
          {
            name: "setup",
            color: "cyan",
            icon: "tools",
            rules: ["RLGALGLB002"]
          },
        ],
      };

      return unseenConfigs[this.target.profile_type].concat(
        unseenConfigs.DEFAULT
      );
    },
    targetKpis() {
      const kpiConfigs = {
        DEFAULT: ["getTopLoyaltyCardsAlerts", "getTopLoyaltyCardsRisk"],
        MAGASINS: ["getTopCashiersAlerts", "getTopCashiersRisk"],
        GALEC: [],
      };

      return kpiConfigs[this.target.profile_type].concat(kpiConfigs.DEFAULT);
    },
    ...mapState({
      target: (state) => state.account.currentTarget,
      cashregisterCounter: (state) => state.kpi.cashregisterCounter,
      loyaltycardCounter: (state) => state.kpi.loyaltycardCounter,
      setupCounter: (state) => state.kpi.setupCounter,
      topCashiersAlertsData: (state) => state.kpi.topCashiersAlerts,
      topCashiersRiskData: (state) => state.kpi.topCashiersRisk,
      topLoyaltyCardsAlertsData: (state) => state.kpi.topLoyaltyCardsAlerts,
      topLoyaltyCardsRiskData: (state) => state.kpi.topLoyaltyCardsRisk,
    }),
  },
  methods: {
    getKpis: function () {
      this.unseenCards.forEach((v) => {
        this.$store.dispatch("kpi/getUnseenAlerts", v.name);
      });

      this.targetKpis.forEach((kpi) => {
        this.$store.dispatch(`kpi/${kpi}`);
      });
    },
    getCounter: function (counterType) {
      return eval(`this.${counterType}Counter`);
    },
  },
  created() {
    this.getKpis();
  },
  watch: {
    target: function () {
      this.getKpis();
    },
  },
};
</script>
