import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import qs from "qs";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/pwd",
    name: "ChangePwd",
    component: () => import("@/views/ChangePassword"),
  },
  {
    path: "/profil",
    name: "Profil",
    component: () => import("@/views/Profil"),
  },
  {
    path: "/alerts",
    name: "Alerts",
    component: () => import("@/views/Alerts"),
  },
  {
    path: "/insights",
    name: "Insights",
    component: () => import("@/views/Insights"),
  },
  {
    path: "/alerts/:id",
    name: "Alert",
    component: () => import("@/views/AlertDetails"),
    props: true,
  },
  {
    path: "/rules",
    name: "Rules",
    component: () => import("@/views/Rules"),
  },
  {
    path: "/documentation",
    name: "Documentation",
    component: () => import("@/views/Documentation"),
  },
  {
    path: "/faq",
    name: "FAQ",
    component: () => import("@/views/FAQ"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("@/views/Contact"),
  },
  // {
  //   path: "/changelog",
  //   name: "Changelog",
  //   component: () => import("@/views/Changelog"),
  // },
  {
    path: "/redirect",
    name: "Redirect",
    beforeEnter: (to) => {
      window.open(`${to.params.redirect}/${to.params.path}?${qs.stringify(to.query)}`, "_blank");
    }
  },
  {
    path: "*",
    name: "404",
    component: () => import("@/views/PageNotFound"),
  },
];

export default routes;
