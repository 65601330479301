<template>
  <v-app>
    <NavDrawer
      v-if="!$vuetify.breakpoint.smAndDown && !['Login'].includes($route.name)"
    />

    <NavDrawerMobile v-if="!$vuetify.breakpoint.mdAndUp" ref="mobileDrawer" />

    <NavBar
      @toggle-mobiledrawer="
        $refs.mobileDrawer.mobileDrawer = !$refs.mobileDrawer.mobileDrawer
      "
    />

    <v-main>
      <router-view class="mb-12" />

      <Footer />
    </v-main>

    <Overlay />
    <SnackBar />
  </v-app>
</template>

<script>
import { onAuthUIStateChange } from "@aws-amplify/ui-components";

export default {
  name: "App",
  components: {
    NavDrawer: () => import("./components/Layout/NavDrawer"),
    NavDrawerMobile: () => import("./components/Layout/NavDrawerMobile"),
    NavBar: () => import("./components/Layout/NavBar"),
    Footer: () => import("./components/Layout/Footer"),
    Overlay: () => import("./components/Layout/Overlay"),
    SnackBar: () => import("./components/Layout/SnackBar"),
  },
  created() {
    onAuthUIStateChange((authState, authData) => {
      this.$store.dispatch("account/updateAuthState", {
        authState,
        authData,
      });
    });
  },
  beforeDestroy() {
    return onAuthUIStateChange;
  },
};
</script>

<style>
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
