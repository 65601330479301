import { HTTP } from "./http-common.js";
import router from "@/router";

// initial state
const state = () => ({
  processing: false,
});

// getters
const getters = {
  //
};

// actions
const actions = {
  sendMessage({ commit, rootState }, payload) {
    commit("layout/SET_OVERLAY_LOADER", true, { root: true });

    let payloadData = null;
    if (payload.alertId) {
      payloadData = {
        type: payload.subject,
        message: payload.message,
        alert_id: payload.alertId,
      };
    } else {
      payloadData = {
        type: payload.subject,
        message: payload.message,
      };
    }

    return HTTP.post(
      `targets/${rootState.account.currentTarget.profile_type}:${rootState.account.currentTarget.target}/issues`,
      payloadData,
      { headers: { "Content-Type": "text/json" } }
    )
      .then(() => {
        commit("layout/SET_OVERLAY_LOADER", false, { root: true });

        if (router.currentRoute.name === "Contact") {
          router.push({ path: "/" });
        }
        commit(
          "layout/SET_SNACK_DATAS",
          {
            text: "Votre message a bien été transmis au support",
            color: "success",
          },
          { root: true }
        );
      })
      .catch((error) => {
        commit("layout/SET_OVERLAY_LOADER", false, { root: true });
        commit(
          "layout/SET_SNACK_DATAS",
          {
            text:
              "Une erreur est survenue, votre message n'a pas pu être envoyé",
            color: "error",
          },
          { root: true }
        );

        console.log(error);
      });
  },
};

// mutations
const mutations = {
  //
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
