// initial state
const state = () => ({
  overlayLoader: false,
  snack: false,
  snackText: "",
  snackColor: "",
});

// getters
const getters = {
  snackbarDatas: (state) => {
    const snackDatas = {
      visible: state.snack,
      text: state.snackText,
      color: state.snackColor,
    };
    return snackDatas;
  },
};

// actions
const actions = {
  //
};

// mutations
const mutations = {
  SET_OVERLAY_LOADER(state, data) {
    state.overlayLoader = data;
  },

  SET_SNACK_DATAS(state, data) {
    state.snack = true;
    state.snackText = data.text;
    state.snackColor = data.color;
  },

  CLOSE_SNACKBAR(state) {
    state.snack = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
