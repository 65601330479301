import { HTTP } from "./http-common";
import router from "@/router";

// initial state
const state = () => ({
  authState: null,
  cognito: null,
  userLoader: false,
  user: null,
  userBundle: null,
  targets: [],
  currentPermissions: null,
  currentTarget: [],
});

// getters
const getters = {
  //
};

// actions
const actions = {
  updateAuthState({ commit }, payload) {
    commit("SET_AUTH_STATE", payload.authState);
    commit("SET_COGNITO", payload.authData);
  },

  fetchUser({ state, commit }) {
    commit("layout/SET_OVERLAY_LOADER", true, { root: true });
    state.userLoader = true;

    return HTTP.get("users/profile")
      .then((result) => {
        commit("layout/SET_OVERLAY_LOADER", false, { root: true });
        state.userLoader = false;

        commit("SET_USER", result.data.user);
        commit("SET_USER_BUNDLE", result.data.bundles);
        commit("SET_TARGETS", result.data.targets);

        const savedTarget = localStorage.getItem("savedTarget");
        const existingTarget = state.targets.find(
          (t) => t.target === savedTarget
        );
        if (savedTarget && savedTarget !== "null" && existingTarget) {
          commit("SET_CURRENT_TARGET", existingTarget);
        } else {
          commit("SET_CURRENT_TARGET", result.data.targets[0]);
        }

        this.dispatch("alerts/getUnseenAlertsCount");
      })
      .catch((error) => {
        commit("layout/SET_OVERLAY_LOADER", false, { root: true });
        state.userLoader = false;
        this.dispatch("account/purgeSession");

        console.log(error);
      });
  },

  changeCurrentTarget({ state, commit }, payload) {
    if (payload !== state.currentTarget) {
      commit("layout/SET_OVERLAY_LOADER", true, { root: true });

      commit("SET_CURRENT_TARGET", payload);
      if (router.currentRoute.name !== "Home") {
        router.push("/");
      }

      this.dispatch("alerts/getUnseenAlertsCount");

      commit(
        "layout/SET_SNACK_DATAS",
        {
          text: "Vous avez changé de magasin",
          color: "success",
        },
        { root: true }
      );

      commit("layout/SET_OVERLAY_LOADER", false, { root: true });
    }
  },

  purgeSession({ commit }) {
    commit("SET_COGNITO", null);
    commit("SET_AUTH_STATE", null);
    commit("SET_USER", null);
    commit("SET_USER_BUNDLE", null);
    commit("SET_PERMISSIONS", null);
    commit("SET_TARGETS", []);
    commit("SET_CURRENT_TARGET", []);
  },

  downloadDocument({ rootState }, fileName) {
    HTTP.get(
      `targets/${rootState.account.currentTarget.profile_type}:${rootState.account.currentTarget.target}/files/${fileName.file}`
    )
      .then((result) => {
        window.open(result.data.url, "_blank");
      })
      .catch((error) => {
        console.log(error);
      });
  },

  setMailNotification({ commit }, notifyState) {
    const data = {
      notify: notifyState,
    };

    const options = {
      headers: { "Content-Type": "text/json" },
    };

    return HTTP.put(
      `users/profile`,
      data,
      options
    )
      .then(() => {
        commit(
          "layout/SET_SNACK_DATAS",
          {
            text:
              notifyState ?
                "Vous recevrez un email hebdomadaire (le jeudi) si vous ne vous connectez pas pendant 7 jours et que des nouvelles alertes sont disponibles"
                : "Attention, en cas de non connexion pendant 7 jours, vous ne recevrez plus d'email quand de nouvelles alertes seront disponibles",
            color: "info",
          },
          { root: true }
        )
      })
      .catch((error) => {
        commit(
          "layout/SET_SNACK_DATAS",
          {
            text:
              "Une erreur est survenue, merci de réessayer plus tard",
            color: "error",
          },
          { root: true }
        );

        console.log(error);
      });
  },
};

// mutations
const mutations = {
  SET_AUTH_STATE(state, data) {
    state.authState = data;
  },
  SET_COGNITO(state, data) {
    state.cognito = data;
  },
  SET_USER(state, data) {
    state.user = data;
  },
  SET_USER_BUNDLE(state, data) {
    state.userBundle = data;
  },
  SET_TARGETS(state, data) {
    state.targets = data;
  },
  SET_CURRENT_TARGET(state, data) {
    state.currentTarget = data;
    const currentTarget = state.targets.find((t) => t.target === data.target);
    state.currentPermissions =
      (currentTarget && currentTarget.permissions) || [];
    localStorage.setItem("savedTarget", data.target);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
