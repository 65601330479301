import { HTTP } from "./http-common.js";
import qs from "qs";

// initial state
const state = () => ({
  rules: [],
  rulesNames: [],
});

const qsOptions = {
  encode: false,
  arrayFormat: "comma",
};

// getters
const getters = {
  formattedRules: (state) => {
    const formattedRules = state.rules
      .map((rule) => {
        const cleanedMetas = Object.keys(rule.meta).filter((m) =>
          m.startsWith("use_case")
        );

        return {
          ...rule,
          meta: cleanedMetas.reduce((acc, key) => {
            acc[key] = rule.meta[key];
            return acc;
          }, {}),
        };
      })
      .reduce((acc, value) => {
        const previous = acc.find((r) => r.name === value.subtype);

        if (!previous) {
          acc.push({
            name: value.subtype,
            id: value.subtype,
            children: [value],
          });
        } else {
          previous.children.push(value);
        }

        return acc;
      }, []);

    return formattedRules;
  },
};

// actions
const actions = {
  // RULES LIST
  loadRules({ commit, rootState }) {
    const queryParams = {
      columns: [
        "id",
        "version",
        "name",
        "type",
        "subtype",
        "profile",
        "scope",
        "description",
        "value",
        "financial_risk",
        "cause",
        "meta",
      ],
      filters: {
        type: "RULES",
      },
      limit: 0,
    };

    return HTTP.get(
      `targets/${rootState.account.currentTarget.profile_type}:${rootState.account.currentTarget.target}/queries?${qs.stringify(
        queryParams,
        qsOptions
      )}`
    )
      .then((result) => {
        commit("SET_RULES", result.data.items);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  // RULES NAMES LIST
  loadRulesNames({ commit, rootState }) {
    const queryParams = {
      columns: [
        "rule_id",
        "scope",
        "description",
        "name",
        "val_type",
        "value",
        "val_aggregation",
        "financial_risk",
        "financial_risk_aggregation",
        "meta"
      ],
      filters: {
        type: "RULES",
      },
      sort: {
        column: 'rule_id',
        order: 'ASC',
      },
      limit: 0,
    };

    return HTTP.get(
      `targets/${rootState.account.currentTarget.profile_type}:${rootState.account.currentTarget.target}/queries?${qs.stringify(
        queryParams,
        qsOptions
      )}`
    )
      .then((result) => {
        const rules = result.data.items.reduce((acc, value) => {
          acc[value.rule_id] = {
            ...value,
          };
          return acc;
        }, {});

        commit("SET_RULES_NAMES", rules);
      })
      .catch((error) => {
        console.log(error);
      });
  },
};

// mutations
const mutations = {
  SET_RULES(state, data) {
    state.rules = data;
  },

  SET_RULES_NAMES(state, data) {
    state.rulesNames = data;
  },

  SET_RULE_DETAIL(state, data) {
    state.ruleDetail = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
