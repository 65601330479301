import { HTTP } from "./http-common.js";
import qs from "qs";
import dayjs from "dayjs";

// initial state
const state = () => ({
  kpiLoader: false,
  cashregisterCounter: 0,
  loyaltycardCounter: 0,
  setupCounter: 0,
  topCashiersAlerts: [],
  topCashiersRisk: [],
  topLoyaltyCardsAlerts: [],
  topLoyaltyCardsRisk: [],
  kpiDateRange: [
    dayjs()
      .subtract(7, "day")
      .format("YYYY-MM-DD"),
    dayjs().format("YYYY-MM-DD"),
  ],
});

const qsOptions = {
  encode: false,
  arrayFormat: "comma",
};

// getters
const getters = {
  //
};

// actions
const actions = {
  // UNSEEN ALERTS COUNT
  getUnseenAlerts({ state, rootState, commit }, counterType) {
    const queryParams = {
      daterange: state.kpiDateRange,
      columns: "alert_count",
      filters: {
        seen: false,
        triggered: true,
        last_alert: true,
      },
      limit: 100,
    };

    const targetType = rootState.account.currentTarget.profile_type;

    const rulesConfig = [
      {
        name: "cashregister",
        rules: {
          MAGASINS: [
            "RLMAGCAI001",
            "RLMAGCAI002",
            "RLMAGCAI003",
            "RLMAGCAI004",
          ],
        },
      },
      {
        name: "loyaltycard",
        rules: {
          MAGASINS: ["RLMAGCPT001", "RLMAGCPT002", "RLMAGCPT003", "RLMAGCPT010", "RLMAGCPT011"],
          GALEC: ["RLGALCPT001", "RLGALCPT002", "RLGALCPT010", "RLGALCPT011", "RLGALCPT012", "RLGALCPT013"],
        },
      },
      {
        name: "setup",
        rules: {
          MAGASINS: ["RLMAGGLB001", "RLMAGGLB003", "RLMAGCAI007", "RLMAGCAI008"],
          GALEC: ["RLGALGLB002"],
        },
      },
    ];

    queryParams.filters.rule_id = rulesConfig.find(
      (el) => el.name === counterType
    ).rules[targetType];

    return HTTP.get(
      `targets/${rootState.account.currentTarget.profile_type}:${rootState.account.currentTarget.target}/alerts?${qs.stringify(
        queryParams,
        qsOptions
      )}`
    )
      .then((result) => {
        commit(
          "SET_" + counterType.toUpperCase() + "_COUNTER",
          result.data.items[0].alert_count
        );
      })
      .catch((error) => {
        console.log(error);
      });
  },

  // TOP CASHIERS ALERTS
  getTopCashiersAlerts({ rootState, state, commit }) {
    const queryParams = {
      daterange: state.kpiDateRange,
      columns: ["key", "alert_count"],
      filters: {
        triggered: true,
        last_alert: true,
        kind: "CAISSIER",
      },
      sort: {
        column: "alert_count",
        order: "DESC",
      },
      limit: 5,
    };

    if (rootState.account.currentTarget.profile_type === "MAGASINS") {
      queryParams.filters.rule_id = [
        "RLMAGCAI001",
        "RLMAGCAI002",
        "RLMAGCAI003",
        "RLMAGCAI004",
      ];
    }

    state.kpiLoader = true;

    return HTTP.get(
      `targets/${rootState.account.currentTarget.profile_type}:${rootState.account.currentTarget.target}/alerts?${qs.stringify(
        queryParams,
        qsOptions
      )}`
    )
      .then((result) => {
        state.kpiLoader = false;
        commit("SET_TOP_CASHIERS_ALERTS", result.data.items);
      })
      .catch((error) => {
        state.kpiLoader = false;
        commit("SET_TOP_CASHIERS_ALERTS", []);
        console.log(error);
      });
  },

  // TOP CASHIERS RISK
  getTopCashiersRisk({ rootState, state, commit }) {
    const queryParams = {
      daterange: state.kpiDateRange,
      columns: ["key", "triggered_financial_risk_sum"],
      filters: {
        triggered: true,
        last_alert: true,
        kind: "CAISSIER",
      },
      sort: {
        column: "triggered_financial_risk",
        order: "DESC",
      },
      limit: 5,
    };

    if (rootState.account.currentTarget.profile_type === "MAGASINS") {
      queryParams.filters.rule_id = [
        "RLMAGCAI001",
        "RLMAGCAI002",
        "RLMAGCAI003",
        "RLMAGCAI004",
      ];
    }

    state.kpiLoader = true;

    return HTTP.get(
      `targets/${rootState.account.currentTarget.profile_type}:${rootState.account.currentTarget.target}/alerts?${qs.stringify(
        queryParams,
        qsOptions
      )}`
    )
      .then((result) => {
        state.kpiLoader = false;
        commit("SET_TOP_CASHIERS_RISK", result.data.items);
      })
      .catch((error) => {
        state.kpiLoader = false;
        commit("SET_TOP_CASHIERS_RISK", []);
        console.log(error);
      });
  },

  // TOP LOYALTY CARDS ALERTS
  getTopLoyaltyCardsAlerts({ rootState, state, commit }) {
    const queryParams = {
      daterange: state.kpiDateRange,
      columns: ["key", "alert_count"],
      filters: {
        triggered: true,
        last_alert: true,
        kind: "COMPTE_DE_LA_CARTE",
      },
      sort: {
        column: "alert_count",
        order: "DESC",
      },
      limit: 5,
    };

    if (rootState.account.currentTarget.profile_type === "MAGASINS") {
      queryParams.filters.rule_id = [
        "RLMAGCPT001",
        "RLMAGCPT002",
        "RLMAGCPT003",
      ];
    } else if (rootState.account.currentTarget.profile_type === "GALEC") {
      queryParams.filters.rule_id = ["RLGALCPT001", "RLGALCPT002"];
    }

    state.kpiLoader = true;

    return HTTP.get(
      `targets/${rootState.account.currentTarget.profile_type}:${rootState.account.currentTarget.target}/alerts?${qs.stringify(
        queryParams,
        qsOptions
      )}`
    )
      .then((result) => {
        state.kpiLoader = false;
        commit("SET_TOP_LOYALTY_CARDS_ALERTS", result.data.items);
      })
      .catch((error) => {
        state.kpiLoader = false;
        commit("SET_TOP_LOYALTY_CARDS_ALERTS", []);
        console.log(error);
      });
  },

  // TOP LOYALTY CARDS RISK
  getTopLoyaltyCardsRisk({ rootState, state, commit }) {
    const queryParams = {
      daterange: state.kpiDateRange,
      columns: ["key", "triggered_financial_risk_sum"],
      filters: {
        triggered: true,
        last_alert: true,
        kind: "COMPTE_DE_LA_CARTE",
      },
      sort: {
        column: "triggered_financial_risk",
        order: "DESC",
      },
      limit: 5,
    };

    if (rootState.account.currentTarget.profile_type === "MAGASINS") {
      queryParams.filters.rule_id = [
        "RLMAGCPT001",
        "RLMAGCPT002",
        "RLMAGCPT003"
      ];
    } else if (rootState.account.currentTarget.profile_type === "GALEC") {
      queryParams.filters.rule_id = [
        "RLGALCPT001",
        "RLGALCPT002"
      ];
    }

    state.kpiLoader = true;

    return HTTP.get(
      `targets/${rootState.account.currentTarget.profile_type}:${rootState.account.currentTarget.target}/alerts?${qs.stringify(
        queryParams,
        qsOptions
      )}`
    )
      .then((result) => {
        state.kpiLoader = false;
        commit("SET_TOP_LOYALTY_CARDS_RISK", result.data.items);
      })
      .catch((error) => {
        state.kpiLoader = false;
        commit("SET_TOP_LOYALTY_CARDS_RISK", []);
        console.log(error);
      });
  },
};

// mutations
const mutations = {
  SET_CASHREGISTER_COUNTER(state, data) {
    state.cashregisterCounter = data;
  },
  SET_LOYALTYCARD_COUNTER(state, data) {
    state.loyaltycardCounter = data;
  },
  SET_SETUP_COUNTER(state, data) {
    state.setupCounter = data;
  },
  SET_TOP_CASHIERS_ALERTS(state, data) {
    state.topCashiersAlerts = data;
  },
  SET_TOP_CASHIERS_RISK(state, data) {
    state.topCashiersRisk = data;
  },
  SET_TOP_LOYALTY_CARDS_ALERTS(state, data) {
    state.topLoyaltyCardsAlerts = data;
  },
  SET_TOP_LOYALTY_CARDS_RISK(state, data) {
    state.topLoyaltyCardsRisk = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
