const analysisStatuses = {
  IN_PROGRESS: ['EVOLUTEL', 'COMPLEX', 'OTHER', 'IN_PROGRESS'],
  FRAUDULENT: [
    'CLIENT_FRAUD',
    'CASHIER_FRAUD',
    'CLIENT_CASHIER_FRAUD',
    'OTHER',
  ],
  NOT_FRAUDULENT: [
    'TEST',
    'CLIENT_USUAL',
    'EMPLOYEE_USUAL',
    'REFUND',
    'DISCOUNT',
    'SPECIFIC',
    'MANY_PURCHASES',
    'DUPLICATE',
    'OPERATING_FAULT',
    'OTHER',
  ],
  // NULL: [
  //   'EVOLUTEL',
  //   'COMPLEX',
  //   'OTHER',
  //   'CLIENT_FRAUD',
  //   'CASHIER_FRAUD',
  //   'CLIENT_CASHIER_FRAUD',
  //   'TEST',
  //   'CLIENT_USUAL',
  //   'EMPLOYEE_USUAL',
  //   'REFUND',
  //   'DISCOUNT',
  //   'SPECIFIC',
  //   'MANY_PURCHASES',
  //   'DUPLICATE',
  //   'OPERATING_FAULT',
  //   'IN_PROGRESS',
  //   'FRAUDULENT',
  //   'NOT_FRAUDULENT',
  // ],
};

const state = () => ({
  analysisStatuses,
});

const getters = {
  relevantStatuses: (state) => {
    return Object.keys(state.analysisStatuses);
  },
};

const actions = {
  //
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
