import dayjs from "dayjs";
import localeFr from "dayjs/locale/fr";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

/**
 * formatDate: default format date
 * @param {string} date
 */
function formatDate(date) {
  if (!date) return "";
  return dayjs(date).format("DD/MM/YYYY");
}

/**
 * formatDateLong: format to long date
 * @param {string} date
 */
function formatDateLong(date) {
  if (!date) return "";
  return dayjs(date)
    .locale(localeFr)
    .format("DD MMMM YYYY");
}

/**
 * humanDate: format to human date
 * @param {string} date
 */
function humanDate(date) {
  if (!date) return "";
  return dayjs(date)
    .locale(localeFr)
    .fromNow();
}

/**
 * formatCurrency: format corrency
 * @param {integer} amount
 */
function formatCurrency(amount) {
  if (!amount) return "";
  return amount.toLocaleString("fr-FR", {
    currency: "EUR",
    minimumFractionDigits: 2,
  });
  // return (Math.round(amount * 100) / 100).toFixed(2);
}

/**
 * formatValue: format value
 * @param {string/integer} value
 * @param {string} unit
 */
function formatValue(value, unit) {
  if (!unit) return value;

  switch (unit) {
    case "percent":
      return (value * 100).toFixed(0);
    case "currency":
      return Math.abs(parseFloat(value).toFixed(2));
    default:
      return value;
  }
}

/**
 * formatUnit: format unit
 * @param {string/integer} value
 * @param {string} unit
 */
function formatUnit(value, unit) {
  if (!unit) return `${value}`;
  if (!value) return value;

  switch (unit) {
    case "percent":
      return `${formatValue(value, unit)} %`;
    case "currency":
      return `${formatCurrency(formatValue(value, unit))} €`;
    default:
      return `${formatValue(value, unit)} ${unit}`;
  }
}

/**
 * formatAlert: format alert
 * @param {object} alert
 * @param {object} options
 */
function formatAlert(alert, { queries }) {
  if (!alert)
    return {
      formatted: {},
      rule: {},
    };

  const query = queries[alert.rule_id];

  if (!query)
    return {
      ...alert,
      formatted: {},
      rule: {},
    };

  return {
    ...alert,
    value: formatValue(alert.value, query.val_type),
    financial_risk: formatValue(alert.financial_risk, query.val_type),
    formatted: {
      date: formatDate(alert.date),
      value: formatUnit(alert.value, query.val_type),
      value_threshold: formatUnit(Math.round(alert.value_threshold), query.val_type),
      triggered_value_threshold: formatUnit(Math.round(alert.triggered_value_threshold), query.val_type),
      financial_risk: formatUnit(alert.financial_risk, "currency"),
      triggered_value: formatUnit(alert.triggered_value, query.val_type),
      triggered_financial_risk: formatUnit(
        alert.triggered_financial_risk,
        "currency"
      ),
      triggered_threshold: formatUnit(alert.triggered_threshold, "currency"),
    },
    rule: query,
    previous: alert.previous
      ? formatAlerts(alert.previous, { queries })
      : undefined,
  };
}

/**
 * formatAlerts: format array of alerts
 * @param {array} alerts
 * @param {object} options
 */
function formatAlerts(alerts, options) {
  if (!alerts || !Array.isArray(alerts)) return [];
  return alerts.map((a) => formatAlert(a, options));
}

export {
  formatAlerts,
  formatAlert,
  formatCurrency,
  formatDate,
  formatDateLong,
  humanDate,
};
