import { HTTP } from "./http-common.js";
import qs from "qs";
import dayjs from "dayjs";
import router from "@/router";

// initial state
const state = () => ({
  alerts: [],
  alertDetail: {},
  alertComments: [],
  alertsLoader: false,
  alertLoader: false,
  commentsLoader: false,
  similarAlerts: [],
  unseenAlertsCount: 0,
  alertsDateRange: [
    dayjs()
      .subtract(7, "day")
      .format("YYYY-MM-DD"),
    dayjs().format("YYYY-MM-DD"),
  ],
  unseenFilter: undefined,
  alertsIcons: {
    CAISSIER: "cash-register",
    CODE_ARTICLE: "cash",
    COMPTE_DE_LA_CARTE: "card-account-details-star",
    CODE_OP_RATION: "sale",
  },
  ruleAlerts: {},
});

const qsOptions = {
  encode: false,
  arrayFormat: "comma",
};

// getters
const getters = {
  //
};

// actions
const actions = {
  // ALERTS LIST
  loadAlerts({ rootState, state, commit }) {
    const queryParams = {
      daterange: state.alertsDateRange,
      columns: [
        "id",
        "date",
        "rule_id",
        "kind",
        "key",
        "triggered_value",
        "value_threshold",
        "triggered",
        "triggered_financial_risk",
        "triggered_duration",
        "triggered_threshold",
        "relevant",
        "relevant_status",
        "seen",
      ],
      filters: {
        triggered: true,
        last_alert: true,
        seen: state.unseenFilter,
      },
      sort: {
        column: "date",
        order: "DESC",
      },
      limit: 0,
    };

    state.alerts = [];
    state.alertsLoader = true;

    return HTTP.get(
      `targets/${rootState.account.currentTarget.profile_type}:${
        rootState.account.currentTarget.target
      }/alerts?${qs.stringify(queryParams, qsOptions)}`
    )
      .then((result) => {
        state.alertsLoader = false;
        commit("SET_ALERTS", result.data.items);
      })
      .catch((error) => {
        state.alertsLoader = false;
        state.alerts = [];
        console.log(error);
      });
  },

  // ALERTS LIST
  loadRuleAlerts(
    { rootState, state, commit },
    { ruleId, seen, limit, page, filters }
  ) {
    const queryParams = {
      daterange: state.alertsDateRange,
      columns: [
        "id",
        "date",
        "rule_id",
        "kind",
        "key",
        "triggered_value",
        "value_threshold",
        "triggered",
        "triggered_value_threshold",
        "triggered_financial_risk",
        "triggered_duration",
        "triggered_threshold",
        "relevant",
        "relevant_status",
        "result_status",
        "seen",
      ],
      filters: {
        triggered: true,
        last_alert: true,
        seen,
        rule_id: ruleId,
        ...filters,
      },
      sort: {
        column: "date",
        order: "DESC",
      },
      limit: limit || 0,
      page: page,
    };

    state.alerts = [];
    state.alertsLoader = true;

    return HTTP.get(
      `targets/${rootState.account.currentTarget.profile_type}:${
        rootState.account.currentTarget.target
      }/alerts?${qs.stringify(queryParams, qsOptions)}`
    )
      .then((result) => {
        state.alertsLoader = false;
        commit("SET_RULE_ID_ALERTS", { ruleId, data: result.data.items });
      })
      .catch((error) => {
        state.alertsLoader = false;
        state.alerts = [];
        console.log(error);
      });
  },

  // ALERT DETAIL & SEEN STATUS
  getAlertDetail({ rootState, state, commit }, alertId) {
    state.alertLoader = true;
    state.alertDetail = {};
    state.similarAlerts = [];
    state.alertComments = [];

    return HTTP.get(
      `targets/${rootState.account.currentTarget.profile_type}:${rootState.account.currentTarget.target}/alerts/${alertId}`
    )
      .then((result) => {
        state.alertLoader = false;
        commit("SET_ALERT_DETAIL", result.data);

        // Update alert if not seen
        if (!result.data.seen) {
          this.dispatch("alerts/updateAlertSeen", { alertId });
        }
      })
      .catch((error) => {
        state.alertLoader = false;
        console.log(error);
        router.push("/");
      });
  },

  // SIMILAR ALERTS LIST
  getSimilarAlerts({ rootState, commit }, payload) {
    const queryParams = {
      daterange: [
        dayjs()
          .subtract(90, "day")
          .format("YYYY-MM-DD"),
        dayjs(payload.latestDate)
          .subtract(1, "day")
          .format("YYYY-MM-DD"),
      ],
      columns: [
        "id",
        "rule_id",
        "date",
        "key",
        "value",
        "value_threshold",
        "financial_risk",
        "triggered",
        "triggered_financial_risk",
        "triggered_duration",
        "triggered_threshold",
      ],
      filters: {
        triggered: true,
        key: payload.key,
        rule_id: payload.ruleId,
      },
      sort: {
        column: "date",
        order: "DESC",
      },
      limit: 100,
    };

    return HTTP.get(
      `targets/${rootState.account.currentTarget.profile_type}:${
        rootState.account.currentTarget.target
      }/alerts?${qs.stringify(queryParams, qsOptions)}`
    )
      .then((result) => {
        commit(
          "SET_SIMILAR_ALERTS",
          result.data.items.slice(0, payload.duration)
        );
      })
      .catch((error) => {
        console.log(error);
      });
  },

  // UNSEEN ALERTS COUNT
  getUnseenAlertsCount({ rootState, commit }) {
    const queryParams = {
      daterange: [
        dayjs()
          .subtract(7, "day")
          .format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD"),
      ],
      columns: ["target", "alert_count", "seen"],
      filters: {
        seen: false,
        triggered: true,
        last_alert: true,
      },
      limit: 100,
    };

    return HTTP.get(
      `targets/${rootState.account.currentTarget.profile_type}:${
        rootState.account.currentTarget.target
      }/alerts?${qs.stringify(queryParams, qsOptions)}`
    )
      .then((result) => {
        commit("SET_UNSEEN_ALERTS_COUNT", result.data.items);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  updateAlertRelevance({ rootState, commit }, { alertData, relevantStatus, resultStatus }) {
    let relevant = null;
    switch (relevantStatus) {
      case 'FRAUDULENT':
        relevant = true;
        break;
      case 'NOT_FRAUDULENT':
        relevant = false;
        break;
      default:
        relevant = null;
        break;
    }

    const data = {
      relevant,
      relevant_status: relevantStatus,
      result_status: resultStatus ? resultStatus : null
    }
    const options = {
      headers: { "Content-Type": "text/json" },
    };

    return HTTP.put(
      `targets/${rootState.account.currentTarget.profile_type}:${rootState.account.currentTarget.target}/alerts/${alertData.id}`,
      data,
      options
    )
      .then(() => {
        if (router.currentRoute.name === 'Alerts') {
          commit("SET_ALERT_RELEVANCE", { alertData, relevant, relevantStatus, resultStatus });

          // update alert as seen
          if (alertData.seen === false) {
            this.dispatch("alerts/updateAlertSeen", {
              alertId: alertData.id,
            });
          }
        } else {
          setTimeout(() => {
            this.dispatch('alerts/fetchAlertComments', alertData.id);
          }, 5000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  updateAlertSeen({ rootState }, { alertId }) {
    const data = {
      seen: true,
    };

    const options = {
      headers: { "Content-Type": "text/json" },
    };

    return HTTP.put(
      `targets/${rootState.account.currentTarget.profile_type}:${rootState.account.currentTarget.target}/alerts/${alertId}`,
      data,
      options
    ).then(() => this.dispatch("alerts/getUnseenAlertsCount"));
  },

  processDateRange({ commit }, dates) {
    if (dates) {
      commit("SET_DATE_RANGE", dates);
    }
  },

  filterUnseen({ commit }, payload) {
    commit("SET_UNSEEN_FILTER", payload), this.dispatch("alerts/loadAlerts");
  },

  fetchAlertComments({ rootState, state, commit }, alertId) {
    state.alertComments = [];

    const queryParams = {
      filters: {
        deleted: false,
      },
      sort: {
        column: "created_at",
        order: "DESC",
      },
      limit: 0,
    };

    const qsOptions = {
      encode: false,
      arrayFormat: "comma",
    };

    state.commentsLoader = true;

    return HTTP.get(
      `targets/${rootState.account.currentTarget.profile_type}:${
        rootState.account.currentTarget.target
      }/alerts/${alertId}/comments?${qs.stringify(queryParams, qsOptions)}`
    )
      .then((result) => {
        state.commentsLoader = false;
        commit("SET_ALERT_COMMENTS", result.data.items);
      })
      .catch((error) => {
        state.commentsLoader = false;
        commit(
          "layout/SET_SNACK_DATAS",
          {
            text: "Impossible de récupérer les commentaires",
            color: "error",
          },
          { root: true }
        );
        console.log(error);
      });
  },

  addAlertComment({ rootState, commit }, { alertId, message }) {
    commit("layout/SET_OVERLAY_LOADER", true, { root: true });

    const data = {
      message: message,
    };

    const options = {
      headers: { "Content-Type": "text/json" },
    };

    return HTTP.post(
      `targets/${rootState.account.currentTarget.profile_type}:${rootState.account.currentTarget.target}/alerts/${alertId}/comments`,
      data,
      options
    )
      .then(() => {
        commit("layout/SET_OVERLAY_LOADER", false, { root: true });
        this.dispatch("alerts/fetchAlertComments", alertId);
      })
      .catch((error) => {
        commit("layout/SET_OVERLAY_LOADER", false, { root: true });
        commit(
          "layout/SET_SNACK_DATAS",
          {
            text: "Votre commentaire n'a pas pu être envoyé",
            color: "error",
          },
          { root: true }
        );
        console.log(error);
      });
  },

  updateComment({ rootState, commit }, { alertId, commentId, message }) {
    commit("layout/SET_OVERLAY_LOADER", true, { root: true });
    const data = {
      message: message,
    };

    const options = {
      headers: { "Content-Type": "text/json" },
    };

    return HTTP.put(
      `targets/${rootState.account.currentTarget.profile_type}:${rootState.account.currentTarget.target}/alerts/${alertId}/comments/${commentId}`,
      data,
      options
    )
      .then(() => {
        commit("layout/SET_OVERLAY_LOADER", false, { root: true });
        this.dispatch("alerts/fetchAlertComments", alertId);
      })
      .catch((error) => {
        commit("layout/SET_OVERLAY_LOADER", false, { root: true });
        commit(
          "layout/SET_SNACK_DATAS",
          {
            text: "Votre commentaire n'a pas pu être modifié",
            color: "error",
          },
          { root: true }
        );
        console.log(error);
      });
  },

  deleteComment({ rootState, commit }, { alertId, commentId }) {
    commit("layout/SET_OVERLAY_LOADER", true, { root: true });
    return HTTP.delete(
      `targets/${rootState.account.currentTarget.profile_type}:${rootState.account.currentTarget.target}/alerts/${alertId}/comments/${commentId}`
    )
      .then(() => {
        commit("layout/SET_OVERLAY_LOADER", false, { root: true });
        this.dispatch("alerts/fetchAlertComments", alertId);
      })
      .catch((error) => {
        commit("layout/SET_OVERLAY_LOADER", false, { root: true });
        commit(
          "layout/SET_SNACK_DATAS",
          {
            text: "Votre commentaire n'a pas pu être supprimé",
            color: "error",
          },
          { root: true }
        );
        console.log(error);
      });
  },
};

// mutations
const mutations = {
  SET_ALERTS(state, data) {
    state.alerts = data;
  },

  SET_RULE_ID_ALERTS(state, { ruleId, data }) {
    state.ruleAlerts = {
      ...state.ruleAlerts,
      [ruleId]: data,
    };
  },

  SET_ALERT_DETAIL(state, data) {
    state.alertDetail = data;
  },

  SET_ALERT_COMMENTS(state, data) {
    state.alertComments = data;
  },

  SET_SIMILAR_ALERTS(state, data) {
    state.similarAlerts = data;
  },

  SET_UNSEEN_ALERTS_COUNT(state, data) {
    state.unseenAlertsCount = data[0] ? data[0].alert_count : 0;
  },

  SET_DATE_RANGE(state, data) {
    state.alertsDateRange = data;
  },

  SET_ALERT_RELEVANCE(state, payload) {
    const ruleIdAlerts = state.ruleAlerts[payload.alertData.rule_id];
    const alert = ruleIdAlerts.find((item) => item.id === payload.alertData.id);

    Object.assign(alert, {
      relevant: payload.relevant,
      relevant_status: payload.relevantStatus,
      result_status: payload.resultStatus,
      seen: true,
    });
  },

  SET_UNSEEN_FILTER(state, data) {
    state.unseenFilter = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
